import { StatusCodes } from "http-status-codes";
import { authorizedApiClient } from "Api";

const api = {
  submission: {
    entities: "submission/formData",
    submit: "submission/submit",
    uploadFile: "submission/uploadFile"
  },
  classes: {
    minorClassesList: "api/classes/minor/list",
    placingBasesList: "api/classes/placing-bases/list",
    classesList: "api/classes/list"
  },
  assured: {
    search: "api/assured/search",
    add: "api/assured/add",
    addressStates: "api/assured/addressStates/list",
    addressCountries: "api/assured/addressCountries/list",
    cedants: "api/assured/miamiCedants/list"
  },
  placingBases: {
    byEntity: "api/placingbases/{entity}"
  },
  producingTeams: {
    byEntity: "api/producingteams/{entity}"
  },
  docTypes: {
    byEntity: "api/doctypes/{entity}"
  },
  uwGroupMembers: {
    byEntity: "api/uwGroupMembers/{entity}"
  },
  policyReference: {
    list: "api/policyReference/list",
    validation: "api/policyReference/validate"
  },
  brokers: {
    list: `api/brokers/{productId}/companies/{companyId}`,
    branches: `api/brokers/{id}/{productId}/companies/{companyId}/branches`,
    contacts: `api/brokers/{id}/{productId}/companies/{companyId}/branches/{branchId}/contacts`,
    territory: `api/aiprocessing/territory/{branchName}`
  }
};

export const fetchClasses = async (businessEntity, majorClass, minorClass) => {
  try {
    let response = await authorizedApiClient.get(
      api.classes.classesList,
      {
        params: {
          businessEntity,
          majorClass,
          minorClass
        }
      });

    return {
      data: response.data,
      success: true
    };
  } catch {
    return {
      success: false,
      errorMessage: "Error occurred during the 'Classes' data loading. Please try to select the 'Minor Class' again."
    };
  }
};

export const fetchMinorClasses = async (businessEntity, majorClass, documentType) => {
  try {
    let response = await authorizedApiClient.get(
      api.classes.minorClassesList,
      {
        params: { businessEntity, majorClass, documentType }
      });

    return {
      success: true,
      data: response.data
    };
  } catch {
    return {
      success: false,
      errorMessage: "Error occurred during the 'Minor Classes' data loading. Please try to select the 'Major Class' again."
    };
  }
};

export const fetchProductPlacingBases = async (businessEntity, majorClass, documentType) => {
  try {
    let response = await authorizedApiClient.get(
      api.classes.placingBasesList,
      {
        params: { businessEntity, majorClass, documentType }
      });

    return {
      success: true,
      data: response.data
    };
  } catch {
    return {
      success: false,
      errorMessage: "Error occurred during the 'Placing Bases' data loading. Please try to select the 'Major Class' again."
    };
  }
};

export const fetchAssuredList = async request => {
  try {
    let response = await authorizedApiClient.post(api.assured.search, request);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during the assured data loading. Please, try again later."
    };
  }
};

export const fetchBrokers = async (productId, companyId, filter) => {
  try {
    let url = api.brokers.list
      .replace("{productId}", productId)
      .replace("{companyId}", companyId);
    let response = await authorizedApiClient.get(
      url,
      {
        params: { filter }
      });

    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during the Brokers loading. Please, try again later."
    };
  }
};

export const fetchBrokerBranches = async (brokerId, productId, companyId, filter) => {
  try {
    let url = api.brokers.branches
      .replace("{id}", brokerId)
      .replace("{productId}", productId)
      .replace("{companyId}", companyId);

    let requestConfig = { params: { filter } };

    let response = await authorizedApiClient.get(url, requestConfig);

    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during the Broker Branches loading. Please, try again later."
    };
  }
};

export const fetchBrokerContacts = async (brokerId, productId, companyId, branchId, filter) => {
  try {
    let url = api.brokers.contacts
      .replace("{id}", brokerId)
      .replace("{productId}", productId)
      .replace("{companyId}", companyId)
      .replace("{branchId}", branchId);

    let requestConfig = { params: { filter } };

    let response = await authorizedApiClient.get(url, requestConfig);

    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during the Broker Contacts loading. Please, try again later."
    };
  }
};

export const addAssured = async (request) => {
  try {
    let response = await authorizedApiClient.post(api.assured.add, request);

    return {
      success: true,
      data: response.data
    };
  } catch {
    return {
      success: false,
      errorMessage: "Error occurred. Assured is not added. Please, try again later."
    };
  }
};

export const fetchAddressStates = async () => {
  try {
    let response = await authorizedApiClient.get(api.assured.addressStates);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during the address states data loading. Please, try again later."
    };
  }
};

export const fetchAddressCountries = async () => {
  try {
    let response = await authorizedApiClient.get(api.assured.addressCountries);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: "Error occurred during the address Countries data loading. Please, try again later."
    };
  }
};

export const fetchMiamiCedants = async () => {
  try {
    let response = await authorizedApiClient.get(api.assured.cedants);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      errorMessage: error.response.data
    };
  }
};

export const fetchPlacingBases = async entity => {
  try {
    let url = api.placingBases.byEntity.replace("{entity}", entity);
    let response = await authorizedApiClient.get(url);
    return {
      success: true,
      data: response?.data
    };
  } catch {
    return {
      success: false,
      errorMessage: "Error occurred during the Placing Bases data loading."
    };
  }
};

export const fetchProducingTeams = async entity => {
  try {
    let url = api.producingTeams.byEntity.replace("{entity}", entity);
    let response = await authorizedApiClient.get(url);
    return {
      success: true,
      data: response?.data
    };
  } catch {
    return {
      success: false,
      errorMessage: "Error occurred during the Producing Teams data loading."
    };
  }
};

export const fetchDocTypes = async entity => {
  try {
    let url = api.docTypes.byEntity.replace("{entity}", entity);
    let response = await authorizedApiClient.get(url);
    return {
      success: true,
      data: response?.data
    };
  } catch {
    return {
      success: false,
      errorMessage: "Error occurred during the Document Types data loading."
    }
  }
}

export const fetchUWGroupMembers = async entity => {
  try {
    let url = api.uwGroupMembers.byEntity.replace("{entity}", entity);
    let response = await authorizedApiClient.get(url);
    return {
      success: true,
      data: response?.data
    };
  } catch {
    return {
      success: false,
      errorMessage: "Error occurred during the Assigned UWs data loading."
    }
  }
}

export const fetchEntitiesData = async () => {
  try {
    let response = await authorizedApiClient.get(api.submission.entities);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    if (error.response.status === StatusCodes.FORBIDDEN) {
      const message = "You do not have permission to access this application, please contact your manager to get access.";
      return {
        success: false,
        errorMessage: error.response.data ?? message,
        permissionError: true
      };
    }

    return {
      success: false,
      errorMessage: "Error occurred during the page loading. Please, try again."
    };
  }
};

export const fetchPolicyReferences = async (assuredId, productId) => {
  try {
    let response = await authorizedApiClient.get(api.policyReference.list, {
      params: { assuredId, productId }
    });
    return {
      success: true,
      data: response.data
    };
  } catch {
    return {
      success: false,
      errorMessage: "Error occurred during the Policy References data loading. Please, try again later."
    };
  }
};

export const submitFormData = async (form, files) => {
  try {
      await authorizedApiClient.post(
      api.submission.submit,
      {
        ...form,
        files: files
      });

    return { success: true };
  } catch (e) {
    return {
      success: false,
      errorMessage: "Submission is failed. Errors occurred: " + e?.response?.data?.errors?.join("; ")
    };
  }
};

export const uploadFile = async (fileName, content) => {
  try {
    var response = await authorizedApiClient.post(
      api.submission.uploadFile,
      content,
      {
        params: { fileName }
      });

    return { success: true, uploadPath: response.data.uploadPath };
  } catch (error) {
    return { success: false, error };
  }
}

export const fetchBrokerBranchTerritory = async (branchName) => {
  try {
    let url = api.brokers.territory.replace("{branchName}", branchName);
    let response = await authorizedApiClient.get(url);
    return {
      success: true,
      data: response?.data
    };
  } catch {
    return {
      success: false,
      errorMessage: "Error occurred during the Broker Branch Territory data loading."
    }
  }
}