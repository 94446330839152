import axios from 'axios';

import { getAuthorizationProvider } from '../Authentication';

export const authorizedApiClient = axios.create({
  timeout: 60000,
});

const addRequestInterceptors = () => {
  const beforeRequest = async (config) => {
    var authorizationProvider = await getAuthorizationProvider();
    let { auth } = authorizationProvider.getCurrentConfiguration();

    const checkTokenRequest = {
      scopes: [auth.clientId],
    };

    try {
      const response = await authorizationProvider.acquireTokenSilent(
        checkTokenRequest,
      );
      config.headers.Authorization = `Bearer ${response.idToken.rawIdToken}`;
      return config;
    } catch (error) {
      authorizationProvider.acquireTokenRedirect(checkTokenRequest);
    }
  };

  authorizedApiClient.interceptors.request.use(beforeRequest);
};

const addResponseInterceptors = () => {
  authorizedApiClient.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        error.message =
          'The server is temporarily taking longer to respond. Please wait a moment and try again.';
      }
      return Promise.reject(error);
    },
  );
};

export const configureInterceptors = () => {
  addRequestInterceptors();
  addResponseInterceptors();
};
